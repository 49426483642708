.value {
    background-color: $color-3;
    margin: 0;
    clip-path: polygon(100% 0, 100% 90%, 50% 100%, 0 100%, 0 0);
    @include for-tab {
        clip-path: polygon(100% 0, 100% 80%, 50% 100%, 0 100%, 0 0);
        padding: 30px;
    }
    @include for-laptop {
        clip-path: polygon(100% 0, 100% 60%, 50% 100%, 0 100%, 0 0);
        display: flex;
    }
    @include for-desktop {
        height: 550px;
    }
}
.value__left {
    padding: 12.5px;
    @include for-laptop {
        width: 50%;
        margin-left: 50px;
    }
    @include for-desktop {
        margin-left: 150px;
    }
    p,
    h1 {
        margin-bottom: 30px;
    }
}
.value__right {
    height: 300px;
    position: relative;
    @include for-tab {
        height: 350px;
    }
    @include for-laptop {
        width: 50%;
        height: 400px;
    }
    @include for-desktop {
        margin-right: 50px;
    }
}
.value__hero {
    display: block;
    width: auto;
    height: 250px;
    transform: rotate(-25deg);
    margin: 20px auto;
    // position: absolute;
    // bottom: 20px;
    // right: 40px;
    @include for-tab {
        height: calc(250px * 1.3);
        transform: rotate(-30deg);
    }
    @include for-laptop {
        transform: rotate(-35deg);
        margin: 40px auto;
    }
    @include for-desktop {
        height: calc(250px * 1.5);
    }
}
.view {
    @include for-laptop {
        padding: 0 100px;
    }
}
.view__title {
    text-align: center;
}
.step__title {
    text-align: center;
}
.view__projects {
    @include for-tab {
        display: flex;
        justify-content: space-between;
    }
}
.step__bloc {
    margin-bottom: 50px;
    @include for-laptop {
        display: flex;
        flex-direction: column;
    }
    .step__bloc__number {
        text-align: center;
        color: #898989;
        margin-bottom: 20px;
    }
    .step__bloc__title {
        text-align: center;
        margin-bottom: 30px;
    }
    .step__bloc__info {
        padding: 10px;
        @include for-tab {
            max-width: 400px;
            margin: auto;
        }
        @include for-laptop {
            margin: auto 30px;
        }
    }
    .step__bloc__img {
        display: block;
        width: 90%;
        margin: auto;
        @include for-tab {
            max-width: 70%;
        }
        @include for-laptop {
            max-width: 60%;
        }
    }
}
.step__flex {
    @include for-laptop {
        display: flex;
        padding: 0 100px;
    }
}
.step__flex__reverse {
    @include for-laptop {
        flex-direction: row-reverse;
    }
}
.call {
    background-color: $color-4;
    padding: 40px 10px;
    .call__info {
        color: $color-5;
        text-align: center;
        font-size: 25px;
        padding: 80px 5px;
        @include for-tab {
            max-width: 75%;
            margin: auto;
        }
        @include for-desktop {
            max-width: 55%;
            margin: auto;
        }
    }
    .call__question {
        color: $color-5;
        text-align: center;
        margin-bottom: 30px;
    }
}
