.study {
    padding: 20px;
    @include for-laptop {
        margin-top: 30px;
    }
}
.study__title {
    margin-bottom: 30px;
}
.study__title,
.first {
    @include for-tab {
        margin-left: 20px;
    }
    @include for-laptop {
        margin-left: 70px;
    }
    @include for-desktop {
        margin-left: 170px;
    }
}
.study__container {
    @include for-tab {
        display: flex;
        margin: 20px;
    }
    @include for-laptop {
        margin: 20px 70px;
    }
    @include for-desktop {
        margin: 20px 170px;
    }
}
.study__left {
    @include for-tab {
        width: 40%;
    }
    .study__left__p {
        margin-bottom: 20px;
    }
}
.study__right {
    @include for-tab {
        width: 55%;
    }
    .study__right__p {
        font-weight: 500;
        margin-bottom: 20px;
    }
}

.study__img {
    @include for-tab {
        margin: 0 20px;
    }
    @include for-laptop {
        margin: 0 70px;
    }
    @include for-laptop {
        margin: 0 170px;
    }
    .study__image {
        margin-bottom: 30px;
    }
    .img__mockup,
    .img__performance,
    .img__fullScreen,
    .img__col {
        width: 100%;
        height: 100%;
    }
    .img__fullScreen__mobile {
        @include for-tab {
            display: block;
            width: 50%;
            margin: 10px auto;
        }
        @include for-desktop {
            width: 30%;
        }
    }
    .study__image__col {
        @include for-laptop {
            display: flex;
            justify-content: space-evenly;
            margin-bottom: 100px;
        }
        .img__col {
            border: 1px solid $color-2;
            display: block;
            margin: 20px auto;
            @include for-tab {
                margin: 10px auto;
                width: 50%;
            }
            @include for-laptop {
                margin: 10px;
                width: 40%;
            }
            @include for-desktop {
                margin: 10px;
                width: 30%;
            }
        }
    }
}
