.card__link {
    width: 100%;
    flex: 0 0 50%;
}
.card {
    box-shadow: 5px 5px 10px $color-2;
    margin: 10px auto 40px auto;
    width: 90%;
    position: relative;
}
.card__data {
    padding: 30px;
}
.card__title {
    color: $color-1;
    padding-bottom: 10px;
}
.card__cover {
    width: 100%;
}
.card__data__color1 {
    background-color: #f4eebe;
}
.card__data__color2 {
    background-color: #e2ecff;
}
.card__data__color3 {
    background-color: #ffe5d5;
}
.card__data__color4 {
    background-color: #ffdcfe;
}
