.about__title {
    text-align: center;
    padding: 40px 20px 0 20px;
    @include for-laptop {
        width: 600px;
        margin: 0 auto;
    }
}
.about__who {
    padding: 20px;
    margin-bottom: 50px;
    @include for-tab {
        padding-bottom: 100px;
    }
    .about__who__title {
        text-align: center;
    }
}
.about__story {
    padding: 20px 20px 60px 20px;
    background-color: $color-2;
    .about__story__title {
        text-align: center;
        color: $color-5;
    }
    .about__story__text {
        color: $color-5;
        margin-bottom: 40px;
    }
}
.about__who__text,
.about__story__text,
.about__link {
    @include for-tab {
        width: 450px;
        margin: auto;
    }
}
