.toggle-button {
    position: absolute;
    top: 10px;
    right: 30px;
    cursor: pointer;
    height: 50px;
    width: 50px;
    span {
        width: 50px;
        height: 10px;
        border-radius: 5px;
        background-color: $color-1;
        display: block;
        margin-top: 4px;
        transition: 0.5s ease;
    }
}
