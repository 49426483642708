.contact {
    background-color: $color-4;
    padding: 20px;
    .contact__title {
        color: $color-5;
        margin-bottom: 30px;
        text-align: center;
        @include for-tab {
            margin: 20px auto 40px auto;
        }
    }

    .contact__container {
        @include for-laptop {
            width: 500px;
            margin: auto;
        }
        .contact__info {
            color: $color-5;
            margin-bottom: 20px;
        }
    }
}
