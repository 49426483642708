.logo {
    font-family: $font-1;
    font-size: 20px;
    font-weight: 600;
    color: $color-1;
    min-width: 134px;
    @include for-laptop {
        margin-left: 0;
    }
}
