.footer {
    padding: 30px 15px;
    background-color: $color-3;
    @include for-tab {
        padding: 30px 50px;
    }
    @include for-laptop {
        padding: 30px 100px;
    }
    .footer__adress {
        margin: 20px 0;
        font-size: 14px;
        @include for-laptop {
            max-width: 60%;
        }
        @include for-desktop {
            max-width: 45%;
        }
    }
}
