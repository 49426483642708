.contact__form {
    .form__content {
        .form__input {
            background-color: $color-6;
            // background: none;
            border: none;
            width: 100%;
            margin-bottom: 10px;
            padding: 20px;
            opacity: 1;
        }
        .form__input::placeholder {
            opacity: 1;
            border: none;
        }
        .form__area {
            background-color: $color-6;
            border: none;
            width: 100%;
            margin-bottom: 10px;
            opacity: 1;
            padding: 20px;
            font-family: $font-2;
            resize: none;
        }
        .form__area::placeholder {
            opacity: 1;
            font-family: $font-2;
            border: none;
        }
        .white {
            color: #fcfcfc;
        }
        .white::placeholder {
            color: #c4c4c4;
        }
    }
    .form__message {
        margin-top: 10px;
        padding: 12px;
        opacity: 0;
        transition: 0.2s ease;
        color: black;
        border-radius: 4px;
        box-shadow: 0 0 2px rgba(51, 51, 51, 0.3);
    }
}
#not-mail {
    display: none;
    position: absolute;
    top: 4px;
    color: #ff4d4d;
    right: 2px;
    transform-origin: 50% -50%;
}
.error::placeholder {
    color: #ff4d4d;
}
.email__content {
    position: relative;
}
.email__content input {
    width: 100%;
}
@keyframes dongle {
    0% {
        transform: translate(0px, 0px);
    }
    10% {
        transform: translate(-10px, 0px);
    }
    20% {
        transform: translate(10px, 0px);
    }
    30% {
        transform: translate(-10px, 0px);
    }
    40% {
        transform: translate(10px, 0px);
    }
    50% {
        transform: translate(-10px, 0px);
    }
    60% {
        transform: translate(10px, 0px);
    }
    70% {
        transform: translate(-10px, 0px);
    }
    80% {
        transform: translate(10px, 0px);
    }
    90% {
        transform: translate(-10px, 0px);
    }
    100% {
        transform: translate(0px, 0px);
    }
}
