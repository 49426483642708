.header {
    @include for-laptop {
        display: flex;
        align-items: center;
        background-color: $color-3;
        padding: 20px 70px;
    }
}

// #side__bar.active ~ .content {
//     opacity: 0.3;
// }
