@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

$font-1: "Poppins", sans-serif;
$font-2: "Roboto", sans-serif;

$color-1: #ec6335;
$color-2: #333;
$color-3: #ffe4db;
$color-4: #3c3c3c;
$color-5: #ffffff;
$color-6: #6e6e6e;

// Breakpoints

$breakpoints: (
    mobile: 400px,
    tab: 600px,
    laptop: 900px,
    desktop: 1200px,
);

@mixin for-mobile {
    @media (min-width: map-get($breakpoints, mobile)) {
        @content;
    }
}
@mixin for-tab {
    @media (min-width: map-get($breakpoints, tab)) {
        @content;
    }
}
@mixin for-laptop {
    @media (min-width: map-get($breakpoints, laptop)) {
        @content;
    }
}
@mixin for-desktop {
    @media (min-width: map-get($breakpoints, desktop)) {
        @content;
    }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 18px;
    @include for-laptop {
        font-size: 16px;
    }
}
body {
    font-family: "Roboto", sans-serif;
    max-width: 1440px;
    margin: 0 auto;
    color: $color-2;
}
section {
    padding: 1em 0.5em;
}
h1,
h2,
h3 {
    font-family: "Poppins", sans-serif;
    line-height: 120%;
}
h1 {
    font-size: 1.802rem;
    @include for-laptop {
        font-size: 2.441rem;
    }
}
h2 {
    font-size: 1.602rem;
    margin: 80px auto;
    @include for-laptop {
        font-size: 1.953rem;
    }
}
h3 {
    font-size: 1.424rem;
    @include for-laptop {
        font-size: 1.563rem;
    }
}
p {
    font-size: 1rem;
    line-height: 150%;
}
li {
    list-style-type: none;
    font-size: 1rem;
}
a {
    text-decoration: none;
    cursor: pointer;
    font-size: 1rem;
    color: $color-2;
}
.line {
    display: block;
    border-bottom: 3px solid $color-1;
    width: 1.25rem;
    margin-right: 5px;
    transform: translateY(-7px);
}
.bold {
    font-weight: 600;
}
.light {
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: 5px;
}
