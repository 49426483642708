.portfolio__list {
    margin: 60px auto;
    @include for-laptop {
        display: flex;
        flex-wrap: wrap;
        margin: 60px 100px;
    }
}
.portfolio__title {
    margin: 40px auto 20px 15px;
    text-align: center;
}
.portfolio__description {
    margin: 0 auto auto 15px;
    text-align: center;
}
#portfolio__card {
    width: 700px;
}
