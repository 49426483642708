.nav__primary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3rem;
    padding-left: 20px;
    @include for-laptop {
        width: 100%;
    }
}
.nav__primary__icons {
    width: 26px;
    height: 26px;
    margin-right: 20px;
    @include for-laptop {
        display: none;
    }
    .icon--menu {
        background-image: url(../../assets/menu-orange.svg);
        background-repeat: no-repeat;
        background-position: center;
        width: 26px;
        height: 26px;
        cursor: pointer;
    }
    .icon--close {
        background-image: url(../../assets/close-orange.svg);
        background-repeat: no-repeat;
        background-position: center;
        width: 26px;
        height: 26px;
        cursor: pointer;
    }
}

.nav__primary__bar {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 3rem);
    position: absolute;
    top: 3rem;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    @include for-tab {
        width: 100%;
    }
    @include for-laptop {
        position: static;
        flex-direction: row;
        height: auto;
        width: 100%;
        transition: none;
        justify-content: flex-end;
    }
}
.nav__primary__bar.active {
    background: #fff;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
}

.nav__primary__link {
    padding: 10px;
    margin: 10px;
    position: relative;
    color: $color-2;
    text-align: center;
    &:hover {
        color: lighten($color-1, $amount: 5%);
    }
    @include for-laptop {
        text-align: left;
    }
}

.nav__primary__link-active {
    color: $color-1;
    &::after {
        content: "";
        width: 85%;
        height: 2px;
        background-color: $color-1;
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
    }
}
.nav__primary__phone {
    @include for-laptop {
        flex-grow: 2;
        padding-left: 40px;
    }
}

.nav__secondary {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 3rem;
    background-color: lighten($color-1, $amount: 10%);
    @include for-laptop {
        display: none;
    }
    .nav__secondary__link {
        color: #fff;
        padding-left: 15px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        width: 115px;
        height: 26px;
    }
    .portfolio__icon {
        background-image: url(../../assets/bookmark.svg);
        background-repeat: no-repeat;
        background-position: left;
    }
    .contact__icon {
        background-image: url(../../assets/phone_in_talk.svg);
        background-repeat: no-repeat;
        background-position: left;
    }
    .contact__icon2 {
        background-image: url(../../assets/phone_in_talk_black.svg);
        background-repeat: no-repeat;
        background-position: left;
    }
}
