.btn {
    display: block;
    padding: 0.7em 1em;
    width: 230px;
    color: $color-1;
    border: 2px solid $color-1;
    background-color: rgba($color: #000000, $alpha: 0);
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 30px;
    text-align: center;
    &:hover {
        color: $color-5;
        background-color: $color-1;
        border: 2px solid $color-1;
    }
}
.btn--center {
    margin: 1em auto;
    width: 230px;
}
.btn--empty {
    color: $color-1;
    border: 2px solid $color-1;
    background-color: rgba($color: #000000, $alpha: 0);
}
.btn--full {
    color: $color-5;
    border: 2px solid $color-1;
    background-color: $color-1;
}
